#pitchdeck {
  width: 100%;
  background-color: #eff8ff;
  min-height: 120vh;
  position: relative;
  font-family: "AileronRegular", "OpenSansRegular";
}

.pitchdeckheaderdiv {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.pitchdecktitlediv {
  width: 60%;
  padding: 50px 50px 50px 0;
}

.pitchdeckstatsdiv {
  font-family: "AileronRegular", "OpenSansRegular";
  width: 40%;
  padding: 50px 0 50px 50px;
  margin-top: 13px;
  text-align: right;
}

.pitchdeckstatsrow {
  display: flex;
  align-items: start;
  justify-content: flex-end;
}

.pitchdecktext {
  font-family: "AileronRegular", "OpenSansRegular";
  text-align: left;
  font-size: 28px;
  font-weight: bold;
}

.pitchdeckcreatetext {
  font-family: "AileronRegular", "OpenSansRegular";
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  color: #1f5f8b !important;
}

.pitchdecktitlefield .form-control {
  font-size: 28px !important;
  border-color: transparent !important;
  background-color: transparent !important;
}

.pitchdeckanalysebutton {
  background-color: #0f9d58 !important;
  color: white !important;
  border: 1px solid #0f9d58 !important;
  padding: 6px 15px !important;
  margin: 0 !important;
  font-weight: bold !important;
  font-family: "AileronRegular", "OpenSansRegular" !important;
  font-size: 14px !important;
  border-radius: 5px !important;
}

.pitchdeckaddbutton {
  font-family: "AileronRegular", "OpenSansRegular";
  font-size: 14px !important;
  font-weight: bold !important;
  background-color: gainsboro !important;
  color: #29323a !important;
  border: none !important;
  height: 42px;
  padding: 5px 25px !important;
}

.pitchdecksavebutton {
  padding: 5px 25px;
  font-family: "AileronRegular", "OpenSansRegular";
  font-size: 14px;
  font-weight: bold;
  background: transparent;
  outline: none !important;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  border: 2px solid gainsboro;
  z-index: 1;
  margin-left: 20px;
}

.pitchdecksavebutton:after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  z-index: -1;
  background: gainsboro;
  transition: all 0.3s ease;
}

.pitchdecksavebutton:hover:after {
  left: 0;
  width: 100%;
}

.pitchdecksavebutton:active {
  top: 2px;
}

.pitchdeckclosebutton {
  padding: 5px 25px;
  background: transparent;
  outline: none !important;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  width: 90px;
  line-height: 35px;
  padding: 0;
  border: none;
  margin-left: 20px;
}

.pitchdeckclosebutton span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  font-family: "AileronRegular", "OpenSansRegular";
  font-size: 14px;
  font-weight: bold;
}

.pitchdeckclosebutton:before,
.pitchdeckclosebutton:after {
  position: absolute;
  content: "";
  height: 0%;
  width: 2px;
  background: gainsboro;
}

.pitchdeckclosebutton:before {
  right: 0;
  top: 0;
  transition: all 0.1s ease;
}

.pitchdeckclosebutton:after {
  left: 0;
  bottom: 0;
  transition: all 0.1s 0.2s ease;
}

.pitchdeckclosebutton:hover:before {
  transition: all 0.1s 0.2s ease;
  height: 100%;
}

.pitchdeckclosebutton:hover:after {
  transition: all 0.1s ease;
  height: 100%;
}

.pitchdeckclosebutton span:before,
.pitchdeckclosebutton span:after {
  position: absolute;
  content: "";
  background: gainsboro;
}

.pitchdeckclosebutton span:before {
  left: 0;
  top: 0;
  width: 0%;
  height: 2px;
  transition: all 0.1s 0.1s ease;
}

.pitchdeckclosebutton span:after {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
}

.pitchdeckclosebutton span:hover:before {
  width: 100%;
}

.pitchdeckhistorytable {
  border-collapse: collapse;
  width: 100%;
}

.pitchdeckhistorytable > td:first-child {
  width: 50%;
}

.pitchdecktablerow {
  border: 1px solid #dee2e6;
  border-width: 1px 0;
  height: 50px;
  font-size: 16px;
}

.pitchdecktablerow:last-child {
  border-bottom: none;
}

.pitchdecktabledata:first-child:not(.ideavalidationlandingpagetable) {
  width: 40vw !important;
}

.pitchdecktabledata {
  width: 12vw;
}

.pitchdeckselectaction {
  margin-top: 10px;
  width: 80%;
}

.pitchdeckmessagesave {
  font-size: 13px;
  font-weight: bold;
  font-family: "AileronRegular", "OpenSansRegular";
}

.pitchdeckmessageclose {
  padding: 0 !important;
  margin-left: 5px;
  background-color: transparent !important;
  border: none !important;
  color: #155724 !important;
}

.pitchdeckmessageclose:hover {
  background-color: transparent !important;
  border: none !important;
  color: #155724 !important;
}

.pitchdeckrecommended {
  font-size: 16px;
  font-family: "AileronRegular", "OpenSansRegular";
  border: 1px solid #e9ecef;
  border-radius: 0.25rem;
  padding: 20px;
  background-color: #e9ecef;
}

.pitchdeckaddfield {
  margin-left: 5px;
  background-color: transparent !important;
  border: none !important;
  color: black !important;
}

.pitchdeckaddfield:hover {
  background-color: gainsboro !important;
  border: none !important;
  color: black !important;
}

#companyinformation .pitchdeckdetaildiv {
  width: 25%;
}

#companyinformation .pitchdeckanswers {
  margin-bottom: 0.5rem;
}

#companyinformation .pitchdeckanswers:first-child {
  margin: 0.5rem 0;
}

#companyinformation .pitchdeckanswers:hover {
  background: none !important;
}

.financialstatements {
  padding-bottom: 40px;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.financialstatements2 {
  padding-bottom: 20px;
  width: 100%;
}

.financialstatementstable {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 40px;
}

.financialstatementstable > thead {
  border-collapse: collapse;
  width: 100%;
  text-align: center;
}

.financialstatementstable > tbody > tr:nth-child(odd) {
  background-color: aliceblue;
}

.financialstatementstable > td:first-child {
  width: 50%;
}

.financialstatementstablerow {
  border: 1px solid #dee2e6;
  border-width: 1px 0;
  height: 100px;
  font-size: 16px;
}

.financialstatementstablerowview {
  border: 1px solid #dee2e6;
  border-width: 1px 0;
  font-size: 16px;
}

.financialstatementstablerowbreak {
  border: 1px solid #dee2e6;
  border-width: 1px 0;
  height: 50px;
  font-size: 16px;
}

.financialstatementstabledatacenter {
  /* width: 20vw; */
  padding: 1rem 10px;
  text-align: center;
  border-right: 1px solid #dee2e6;
}

.financialstatementstabledatalast {
  /* width: 20vw; */
  padding: 1rem 10px;
  text-align: center;
  border-right: none;
}

.financialstatementstabledataleft {
  width: 40vw;
  padding: 1rem 10px;
  text-align: left;
  border-right: 1px solid #dee2e6;
}

.financialstatementstabledataindent {
  width: 40vw;
  padding: 1rem 10px 10px 2rem;
  text-align: left;
  border-right: 1px solid #dee2e6;
}

.cancelbutton {
  background-color: transparent !important;
  color: #039be5;
  border: 1px solid transparent !important;
  padding: 6px 15px !important;
  margin: 0 10px 0 0 !important;
  /* font-weight: bold !important; */
  font-family: "AileronRegular", "OpenSansRegular" !important;
  font-size: 14px !important;
  border-radius: 5px !important;
}

.cancelbutton:hover {
  text-decoration: underline;
}

@media (max-width: 1200px) {
  .pitchdecktitlediv {
    width: 50%;
  }

  .pitchdeckstatsdiv {
    width: 50%;
  }
}

@media (max-width: 768px) {
  .pitchdeckheaderdiv {
    flex-direction: column;
  }

  .pitchdecktitlediv {
    width: 100%;
    padding: 50px 0 25px 0;
  }

  .pitchdeckstatsdiv {
    width: 100%;
    padding: 25px 0 50px 0;
    text-align: left;
  }
}

@media (max-width: 576px) {
  #companyinformation .pitchdeckdetaildiv {
    width: 100%;
  }
}

@media (max-width: 425px) {
  .pitchdecktablerow {
    font-size: 12px;
  }
}
